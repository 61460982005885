import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import parse from 'react-html-parser';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ConnectWalletContainer } from '../../components/ConnectWallet';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import useAppContext from '../../hooks/useAppContext';
import useCampaignDetails from '../../hooks/useCampaignDetails';
import useCheckIsMobile from '../../hooks/useCheckIsMobile';
import useParticipants from '../../hooks/useParticipants';
import { dayDiff, getTimezoneString } from '../../utils/dateTime';
import JoinCampaign from './components/JoinCampaign';
import ReportCampaign from './components/ReportCampaign';
import Collapsible from './components/Collapsible';
import UserPosts from './components/UserPosts';

export default function CampaignDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { participants } = useParticipants({ campaignId: id });
  const { campaign, isLoaded } = useCampaignDetails(participants);
  const isMobile = useCheckIsMobile();
  const {
    accountState: { user },
  } = useAppContext();

  const showWinners = Boolean(searchParams.get('winners'));

  const campaignWinners = useMemo(
    () => [
      ...(campaign?.holderWinners ?? []),
      ...(campaign?.nonHolderWinners ?? []),
    ],
    [campaign?.holderWinners, campaign?.nonHolderWinners]
  );

  const userHasJoinedCampaign = useMemo(
    () => participants.map((p) => p.userId).includes(user?.uid),
    [participants, user?.uid]
  );

  const isNewCampaign = useMemo(
    () =>
      campaign?.startTime &&
      moment(campaign.startTime.toDate()).diff(moment(), 'days') < 7,
    [campaign?.startTime]
  );

  // TODO: define more detailed data structure for campaign tasks

  if (!isLoaded)
    return (
      <Layout>
        <Loading isLoading />
      </Layout>
    );

  if (!campaign)
    return (
      <Layout>
        <Box textAlign="center" minHeight="500px">
          <h1>404</h1>
          <p>
            Campaign not found! Double check the link you clicked on. <br />
            Or <a href="/">go back to homepage</a>
          </p>
        </Box>
      </Layout>
    );

  return (
    <Layout className="campaign-details">
      <Loading isLoading={isLoading} />
      <Box
        className="container"
        py="0 !important"
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <h1>Campaign Overview</h1>
        <ConnectWalletContainer />
      </Box>
      <Box className="container" pb="0 !important">
        <h2 style={{ margin: 0, fontWeight: 800 }}>
          {campaign.name}{' '}
          {isNewCampaign && <span style={{ color: '#f50061' }}>(NEW)</span>}
        </h2>
        <span style={{ fontWeight: 600 }}>
          Launch: {moment(campaign.startTime.toDate()).format('D MMMM yyyy')}
        </span>
      </Box>
      <Grid className="container" container spacing={2}>
        <Grid item xs={12} md={4}>
          <img
            src={campaign.image}
            alt={campaign.name}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {(campaign.twitter || campaign.instagram || campaign.website) && (
            <Box display="flex" justifyContent="space-around">
              {campaign.twitter && (
                <a href={campaign.twitter} target="_blank" rel="noreferrer" style={{zIndex:999}}>
                  <img
                    src="/images/socials-icon-twitter.png"
                    alt="twitter link"
                    width={30}
                  />
                </a>
              )}
              {campaign.instagram && (
                <a href={campaign.instagram} target="_blank" rel="noreferrer" style={{zIndex:999}}>
                  <img
                    src="/images/socials-icon-instagram.png"
                    alt="instagram link"
                    width={30}
                  />
                </a>
              )}
              {campaign.website && (
                <a
                  href={`${campaign.website.match(/^https?:/) ? '' : '//'}${
                    campaign.website
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  style={{zIndex:999}}
                >
                  <img
                    src="/images/socials-icon-website.png"
                    alt="website link"
                    width={30}
                  />
                </a>
              )}
            </Box>
          )}{' '}
          <Box display="flex" gap={1} justifyContent="center" mt="-1rem">
            <Box fontWeight={600}>
              <p>Campaign:</p>
              <p>
                Start date:
                <br />
                Time:
              </p>
              <p>
                End date:
                <br />
                Time:
              </p>
              <p>Bounty for holders:</p>
              <p>Bounty for non-holders:</p>
              <p>Tasks:</p>
            </Box>
            <Box flex="1">
              <p>{dayDiff(campaign.startTime, campaign.endTime)} days</p>
              <p>
                {moment(campaign.startTime.toDate()).format('DD.MM.yyyy')}
                <br />
                {moment(campaign.startTime.toDate()).format('HH:mm')}{' '}
                {getTimezoneString()}
              </p>
              <p>
                {moment(campaign.endTime.toDate()).format('DD.MM.yyyy')}
                <br />
                {moment(campaign.endTime.toDate()).format('HH:mm')}{' '}
                {getTimezoneString()}
              </p>
              <p>{campaign.holdersBounty.toLocaleString()} $MIRL</p>
              <p>{campaign.nonHoldersBounty.toLocaleString()} $MIRL</p>
              <p>{campaign.taskShort}</p>
            </Box>
            
          </Box>
          
          <JoinCampaign
            name={campaign.name}
            setIsLoading={setIsLoading}
            userHasJoined={userHasJoinedCampaign}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            borderRadius={4}
            p={3}
            sx={{
              background:
                'linear-gradient(180deg, #0D0628 -30.53%, #171032 86.02%)',
            }}
          >
            <Box display="flex" justifyContent="space-between" gap={2}>
              <p style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>
                Participants
              </p>
              <Box
                display="flex"
                alignItems="center"
                gap={0.5}
                sx={{ color: '#b9bdcc' }}
              >
                <PeopleAltIcon fontSize="20" />
                <p style={{ margin: 0 }}>{campaign.participantsCount}</p>
              </Box>
            </Box>
            <Box flex="1">
              {participants.map((p) => (
                <p key={p.userId}>
                  {p.userId.substr(0, 4)}....{p.userId.substr(-4)}
                </p>
              ))}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <ReportCampaign
                campaignName={campaign.name}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showWinners && campaign?.status === 'Closed' ? (
        <Box className="container">
          <h3>Winners:</h3>
          <Grid container spacing={2}>
            {campaignWinners.map((userId) => (
              <Grid key={userId} item xs={6} md={4} xl={3}>
                <Typography
                  textAlign="center"
                  {...(userId === user?.uid && {
                    fontWeight: 'bold',
                    sx: { textDecoration: 'underline' },
                  })}
                >
                  ...{userId.slice(userId.length - 4)}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {user && (
            <Box display="flex" flexDirection="column" alignItems="center">
              {campaignWinners.includes(user?.uid) ? (
                <p>Congrats! You qualified for the Wear2Earn bounty. </p>
              ) : (
                <p>Aww! You didn&apos;t qualify for any Wear2Earn bounty. </p>
              )}
              <button
                className="secondary-btn big"
                onClick={() => navigate('/')}
              >
                {campaignWinners.includes(user?.uid) ? 'Join' : 'Try'} other
                campaigns
              </button>
            </Box>
          )}
        </Box>
      ) : (
        <Collapsible
          isCollapsed={userHasJoinedCampaign}
          containerClassName="container"
          expandButtonTitle="Campaign Details"
          renderSameRowButton={() =>
            ['Ended', 'Closed'].includes(campaign?.status) &&
            campaign?.status === 'Closed' ? (
              <button
                className="secondary-btn with-icon"
                style={{ width: 240 }}
                onClick={() => setSearchParams({ winners: true })}
              >
                View Results
                <ChevronRightIcon />
              </button>
            ) : (
              <button
                disabled
                className="secondary-btn with-icon"
                style={{ width: 240 }}
              >
                View Results (pending)
                <ChevronRightIcon />
              </button>
            )
          }
        >
          <CampaignField
            title="Description"
            htmlContent={campaign.description}
          />
          <CampaignField
            title="The Bounty"
            htmlContent={`<p>Rewards: <br/> ${campaign.holdersBounty && (`<strong>${campaign.holdersBounty} $MIRL tokens</strong> for $MIRL holders;`)}  <br/> ${campaign.nonHoldersBounty !=0 ? (`<strong>${campaign.nonHoldersBounty} $MIRL tokens</strong> total for non-holders</p>`) : ''}</p>`}
          />
          <CampaignField
            title="The Task & Deliverables"
            htmlContent={campaign.task}
          />
          <CampaignField
            title="Winners"
            htmlContent={campaign.winningCriteria}
          />
          <CampaignField title="Summary" htmlContent={campaign.summary} />
        </Collapsible>
      )}
      {userHasJoinedCampaign && (
        <>
          <div className="divider" />
          <UserPosts campaign={campaign} setIsLoading={setIsLoading} />
        </>
      )}
      {showWinners && campaign.isDoneChoosingWinners && (
        <Box mb={5}>
          <button
            className="black-btn"
            onClick={() => setSearchParams({ winners: false })}
          >
            Back
          </button>
        </Box>
      )}
    </Layout>
  );
}

const CampaignField = ({ title, htmlContent }) => (
  <Box>
    <h4>{title}</h4>
    <div>{parse(htmlContent)}</div>
  </Box>
);
