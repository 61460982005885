import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Layout from '../../components/Layout';
import { ConnectWalletContainer } from '../../components/ConnectWallet';
import { callables } from '../../utils/functions';
import useAppContext from '../../hooks/useAppContext';

const Twitter = () => {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');
  const {
    accountState: { user },
  } = useAppContext();

  const verify = async () => {
    try {
      await callables.verifyTwitter({ state, code });
      setStatus('success');
    } catch (err) {
      console.error(err);
      setStatus('error');
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    if (user) {
      verify();
    }
  }, [user]);

  const renderText = () => {
    if (status === 'loading') return 'Verifying your twitter account...';
    if (status === 'success') return 'Your twitter account is verified!';
    if (status === 'error')
      return errorMessage || 'Something is wrong. Please try again!';
    return '';
  };

  return (
    <Layout hideBackBtn>
      <Box
        className="container"
        py="0 !important"
        display="flex"
        justifyContent="space-between"
      >
        <h1
          style={{ width: '17em', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          Wear 2 Earn
        </h1>
        <ConnectWalletContainer />
      </Box>
      <Box
        height="400px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        {status === 'loading' && <CircularProgress color="secondary" />}
        {status === 'success' && <CheckCircleIcon color="success" />}
        <Typography>{renderText()}</Typography>
      </Box>
    </Layout>
  );
};

export default Twitter;
