import { useState, useMemo, useEffect } from 'react';
import { Dialog, Box, Grid, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ShareIcon from '@mui/icons-material/Share';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Loading from './Loading';
import ConfirmationModal from './ConfirmationModal';
import AccountHistory from './AccountHistory';
import environments from '../utils/environments';
import useAppContext from '../hooks/useAppContext';
import { callables } from '../utils/functions';
import { setCookie } from '../utils/cookies';

const { INSTAGRAM_APP_ID, TWITTER_CLIENT_ID, TIKTOK_CLIENT_KEY } = environments;

const validationSchema = yup.object({
  email: yup.string().email('Email is invalid'),
});

const ProfileModal = () => {
  const {
    accountState: { account },
    profileState: {
      profile,
      updateEmail,
      disconnectEmail,
      disconnectTwitter,
      disconnectInstagram,
      disconnectTiktok,
    },
    profileModalState: { isProfileModalOpen, closeProfileModal },
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('profile');
  const [isEmailConfirmationSent, setIsEmailConfirmationSent] = useState(false);
  const [openModal, setOpenModal] = useState(null);

  const openModalData = useMemo(() => {
    if (openModal === 'email')
      return {
        text: 'Do you want to remove your email?',
        confirmText: 'Remove',
        onConfirm: async () => {
          setIsLoading(true);
          try {
            await disconnectEmail();
            setOpenModal(null);
          } catch (err) {
            console.error(err.message);
          }
          setIsLoading(false);
        },
      };

    if (openModal === 'twitter')
      return {
        text: 'Do you want to remove your twitter account? You might need to connect another one because linking socials is compulsory.',
        confirmText: 'Remove',
        onConfirm: async () => {
          setIsLoading(true);
          try {
            await disconnectTwitter();
            setOpenModal(null);
          } catch (err) {
            console.error(err.message);
          }
          setIsLoading(false);
        },
      };

    if (openModal === 'instagram')
      return {
        text: 'Do you want to remove your instagram account? You might need to connect another one because linking socials is compulsory.',
        confirmText: 'Remove',
        onConfirm: async () => {
          setIsLoading(true);
          try {
            await disconnectInstagram();
            setOpenModal(null);
          } catch (err) {
            console.error(err.message);
          }
          setIsLoading(false);
        },
      };

    if (openModal === 'tiktok')
      return {
        text: 'Do you want to remove your TikTok account? You might need to connect another one because linking socials is compulsory.',
        confirmText: 'Remove',
        onConfirm: async () => {
          setIsLoading(true);
          try {
            await disconnectTiktok();
            setOpenModal(null);
          } catch (err) {
            console.error(err.message);
          }
          setIsLoading(false);
        },
      };

    return {};
  }, [
    openModal,
    disconnectEmail,
    disconnectInstagram,
    disconnectTwitter,
    disconnectTiktok,
  ]);

  const initialValues = useMemo(() => {
    if (profile)
      return {
        email: profile.email,
      };

    return {
      email: '',
    };
  }, [profile]);

  const onSubmit = async (values) => {};

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });

  const verifyEmail = async () => {
    if (touched.email && errors.email) return;
    setIsLoading(true);

    try {
      await updateEmail(values.email);
      await callables.createVerifyEmailRequest();
      setIsEmailConfirmationSent(true);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const verifyTwitter = async () => {
    setIsLoading(true);

    try {
      const res = await callables.getTwitterCodeVerifier();
      const { codeVerifier, state } = res.data;
      const verifyUrl = 'https://w2e.mirl.club/verify/twitter';
      const twitterVerifyUrl = new URL('https://twitter.com/i/oauth2/authorize');
      const scopes = ['users.read', 'tweet.read', 'offline.access'];

      twitterVerifyUrl.searchParams.set('client_id', TWITTER_CLIENT_ID);
      twitterVerifyUrl.searchParams.set('response_type', 'code');
      twitterVerifyUrl.searchParams.set('redirect_uri', verifyUrl);
      twitterVerifyUrl.searchParams.set('state', state);
      twitterVerifyUrl.searchParams.set('code_challenge', codeVerifier);
      twitterVerifyUrl.searchParams.set('code_challenge_method', 'plain');
      twitterVerifyUrl.searchParams.set('scope', scopes.join(' '));

      window.open(twitterVerifyUrl);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const verifyInstagram = async () => {
    setIsLoading(true);

    try {
      const domain = window.location.origin;
      window.open(
        `https://api.instagram.com/oauth/authorize?client_id=${INSTAGRAM_APP_ID}&redirect_uri=${domain}/verify/instagram/&scope=user_profile,user_media&response_type=code`
      );
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const verifyTiktok = async () => {
    setIsLoading(true);
    const csrfState = Math.random().toString(36).substring(2);
    setCookie('csrfState', csrfState);
    const domain = window.location.origin;
    const REDIRECT_URL = `${domain}/verify/tiktok/`;

    let url = 'https://www.tiktok.com/auth/authorize/';
    url += `?client_key=${TIKTOK_CLIENT_KEY}`;
    url += '&scope=user.info.basic';
    url += '&response_type=code';
    url += `&redirect_uri=${REDIRECT_URL}`;
    url += `&state=${csrfState}`;

    try {
      window.location.replace(url);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (profile && profile.emailIsVerified && isEmailConfirmationSent) {
      setIsEmailConfirmationSent(false);
    }
  }, [profile]);

  useEffect(() => {
    if (!isProfileModalOpen) setCurrentTab('profile');
  }, [isProfileModalOpen]);

  if (!profile) return null;

  return (
    <Dialog
      open={isProfileModalOpen}
      onClose={closeProfileModal}
      maxWidth="lg"
      fullWidth
    >
      <ConfirmationModal
        isLoading={isLoading}
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        {...openModalData}
      />
      <Loading isLoading={isLoading} />
      <Box width="100%" overflow="auto">
        <Grid container>
          <Grid item xs={12} sm={3} sx={{ borderRight: '1px solid #909090' }}>
            <Box display="flex" flexDirection="column">
              <Box p={2} mb={2}>
                <img
                  src="/images/LogoHeader.png"
                  alt="mirl-logo"
                  style={{ maxWidth: '100%' }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                p={2}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={closeProfileModal}
              >
                <Box
                  p={1}
                  mt={0.5}
                  width="20px"
                  height="20px"
                  border="1px solid white"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ArrowBackIcon sx={{ fontSize: 14, color: 'white' }} />
                </Box>
                <Typography color="white">Back to Dashboard</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                p={2}
                gap={1}
                sx={{
                  cursor: 'pointer',
                  backgroundColor:
                    currentTab === 'profile'
                      ? 'rgba(0, 0, 0, 0.6)'
                      : 'transparent',
                }}
                onClick={() => setCurrentTab('profile')}
              >
                <Box
                  p={1}
                  mt={0.5}
                  width="20px"
                  height="20px"
                  border="1px solid white"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <PersonOutlineOutlinedIcon
                    sx={{ fontSize: 14, color: 'white' }}
                  />
                </Box>
                <Typography color="white">Profile</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                p={2}
                gap={1}
                sx={{
                  cursor: 'pointer',
                  backgroundColor:
                    currentTab === 'account'
                      ? 'rgba(0, 0, 0, 0.6)'
                      : 'transparent',
                }}
                onClick={() => setCurrentTab('account')}
              >
                <Box
                  p={1}
                  mt={0.5}
                  width="20px"
                  height="20px"
                  border="1px solid white"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AttachMoneyOutlinedIcon
                    sx={{ fontSize: 14, color: 'white' }}
                  />
                </Box>
                <Typography color="white">Account History</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              p={2}
              display="flex"
              justifyContent="space-between"
              sx={{ borderBottom: '1px solid #909090' }}
            >
              <Typography fontWeight={700} fontSize={48}>
                MIRL Wear 2 Earn{' '}
                {currentTab === 'profile' ? 'Profile' : 'Account'}
              </Typography>
              <Box
                p={1}
                width="20px"
                height="20px"
                border="1px solid white"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: 'pointer' }}
                onClick={closeProfileModal}
              >
                <CloseIcon sx={{ fontSize: 12, color: 'white' }} />
              </Box>
            </Box>
            {currentTab === 'profile' ? (
              <Box py={4} px={2} display="flex" flexDirection="column" gap={4}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <AccountBalanceWalletIcon sx={{ fontSize: 14 }} />
                    <Typography fontWeight={700} fontSize={22}>
                      Wallets
                    </Typography>
                  </Box>
                  <Typography lineHeight="1rem" sx={{ px: 2 }}>
                    Your wallet helps verify your profile to the campaigns and
                    payments. Rewards will be paid to the wallet address below.{' '}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/images/ethereum.png" alt="network-logo" />
                    <input
                      value={account}
                      disabled
                      style={{
                        flex: 1,
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily:
                          "'DarkerGrotesque', Helvetica Neue, sans-serif",
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <MonetizationOnOutlinedIcon sx={{ fontSize: 14 }} />
                    <Typography fontWeight={700} fontSize={22}>
                      Balance
                    </Typography>
                  </Box>
                  <Typography lineHeight="1rem" sx={{ px: 2 }}>
                    All your campaign bounty will be credited into your profile
                    account balance. You can withdraw your balance in the panel
                    here.
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/logo512.png" alt="$mirl" width={32} />
                    <Box flex={1} display="flex" alignItems="center" gap={1}>
                      <Typography
                        color="#545454"
                        fontSize="20px"
                        fontWeight={600}
                      >
                        {profile.balance.toLocaleString()} $MIRL tokens
                      </Typography>
                    </Box>
                    <Button
                      sx={{ textTransform: 'none' }}
                      disabled={!profile.balance}
                      onClick={() => setCurrentTab('account')}
                    >
                      Claim
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <MailOutlineIcon sx={{ fontSize: 14 }} />
                    <Typography fontWeight={700} fontSize={22}>
                      Email
                    </Typography>
                    <Typography fontSize={22} fontStyle="italic" sx={{ ml: 2 }}>
                      Optional
                    </Typography>
                  </Box>
                  <Typography lineHeight="1rem" sx={{ px: 2 }}>
                    Linking your email helps us to send you campaign updates &
                    reminders. You will be able to get the latest campaigns and
                    results right at your fingertips.
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/images/email.svg" alt="email" />
                    <Box flex={1} display="flex" alignItems="center" gap={1}>
                      {profile.emailIsVerified ? (
                        <Box flex={1} display="flex" gap={1}>
                          <Typography
                            color="#545454"
                            fontSize="20px"
                            fontWeight={600}
                          >
                            {values.email}
                          </Typography>
                          <CheckCircleIcon
                            sx={{ fontSize: 16, color: 'green' }}
                          />
                        </Box>
                      ) : (
                        <input
                          name="email"
                          disabled={isEmailConfirmationSent}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur('email')}
                          placeholder="email here"
                          style={{
                            flex: 1,
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            fontSize: 20,
                            fontWeight: 600,
                            fontFamily:
                              "'DarkerGrotesque', Helvetica Neue, sans-serif",
                          }}
                        />
                      )}
                    </Box>
                    <Button
                      sx={{ textTransform: 'none' }}
                      disabled={
                        (touched.email && errors.email) ||
                        isEmailConfirmationSent
                      }
                      onClick={
                        !profile.emailIsVerified
                          ? verifyEmail
                          : () => setOpenModal('email')
                      }
                    >
                      {!profile.emailIsVerified ? 'Enter' : 'Remove'}
                    </Button>
                  </Box>
                  <Box height="1rem" px={2} mt={-2}>
                    {touched.email && errors.email && (
                      <Typography fontSize={16} color="tomato">
                        {errors.email}
                      </Typography>
                    )}
                    {isEmailConfirmationSent && (
                      <Typography fontSize={16} fontStyle="italic">
                        Please check your email to verify email
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <ShareIcon sx={{ fontSize: 14 }} />
                    <Typography fontWeight={700} fontSize={22}>
                      Social Account
                    </Typography>
                    <Typography
                      fontSize={22}
                      fontStyle="italic"
                      color="tomato"
                      sx={{ ml: 2 }}
                    >
                      Required
                    </Typography>
                  </Box>
                  <Typography lineHeight="1rem" sx={{ px: 2 }}>
                    Linking socials is compulsory, it helps us to track your
                    campaign progress and verify your campaigns.
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/images/twitter-color.svg" alt="twitter" />
                    <Box flex={1} display="flex" gap={1}>
                      <Typography
                        color="#545454"
                        fontSize="20px"
                        fontWeight={600}
                      >
                        {profile.socials?.twitter?.isVerified
                          ? `Connected to @${profile.socials?.twitter?.url}`
                          : 'connect your Twitter account'}
                      </Typography>
                      {profile.socials?.twitter?.isVerified && (
                        <CheckCircleIcon
                          sx={{ fontSize: 16, color: 'green' }}
                        />
                      )}
                    </Box>
                    <Button
                      sx={{ textTransform: 'none' }}
                      // disabled={touched.twitter && errors.twitter}
                      onClick={
                        !profile.socials?.twitter?.isVerified
                          ? verifyTwitter
                          : () => setOpenModal('twitter')
                      }
                    >
                      {!profile.socials?.twitter?.isVerified
                        ? 'Connect'
                        : 'Disconnect'}
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/images/instagram-color.svg" alt="instagram" />
                    <Box flex={1} display="flex" gap={1}>
                      <Typography
                        color="#545454"
                        fontSize="20px"
                        fontWeight={600}
                      >
                        {profile.socials?.instagram?.isVerified
                          ? `Connected to @${profile.socials?.instagram?.url}`
                          : 'connect your Instagram account'}
                      </Typography>
                      {profile.socials?.instagram?.isVerified && (
                        <CheckCircleIcon
                          sx={{ fontSize: 16, color: 'green' }}
                        />
                      )}
                    </Box>
                    <Button
                      sx={{ textTransform: 'none' }}
                      onClick={
                        !profile.socials?.instagram?.isVerified
                          ? verifyInstagram
                          : () => setOpenModal('instagram')
                      }
                    >
                      {!profile.socials?.instagram?.isVerified
                        ? 'Connect'
                        : 'Disconnect'}
                    </Button>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    bgcolor="#e3e2e2"
                    borderRadius={0.5}
                    py={0.75}
                    px={1}
                    mx={2}
                  >
                    <img src="/images/tiktok-color.svg" alt="tiktok" />
                    <Box flex={1} display="flex" gap={1}>
                      <Typography
                        color="#545454"
                        fontSize="20px"
                        fontWeight={600}
                      >
                        {profile.socials?.tiktok?.isVerified
                          ? `Connected to @${profile.socials?.tiktok?.url}`
                          : 'connect your TikTok account'}
                      </Typography>
                      {profile.socials?.tiktok?.isVerified && (
                        <CheckCircleIcon
                          sx={{ fontSize: 16, color: 'green' }}
                        />
                      )}
                    </Box>
                    <Button
                      sx={{ textTransform: 'none' }}
                      onClick={
                        !profile.socials?.tiktok?.isVerified
                          ? verifyTiktok
                          : () => setOpenModal('tiktok')
                      }
                    >
                      {!profile.socials?.tiktok?.isVerified
                        ? 'Connect'
                        : 'Disconnect'}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <AccountHistory
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ProfileModal;
