import { Routes, Route, Navigate } from 'react-router-dom';

import CampaignDetails from '../pages/Campaigns/CampaignDetails';

const CampaignRoute = () => {
  return (
    <Routes>
      <Route path="/:id" element={<CampaignDetails />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default CampaignRoute;
