import { Routes, Route, Navigate } from 'react-router-dom';

import VerifyEmail from '../pages/Verify/Email';
import VerifyTwitter from '../pages/Verify/Twitter';
import VerifyInstagram from '../pages/Verify/Instagram';
import VerifyTiktok from '../pages/Verify/Tiktok';

const VerifyRoute = () => {
  return (
    <Routes>
      <Route path="/email/:id" element={<VerifyEmail />} />
      <Route path="/twitter" element={<VerifyTwitter />} />
      <Route path="/instagram" element={<VerifyInstagram />} />
      <Route path="/tiktok" element={<VerifyTiktok />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default VerifyRoute;
