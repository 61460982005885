import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useAppContext from '../../../hooks/useAppContext';
import useParticipantPost from '../../../hooks/useParticipantPost';
import useCheckIsMobile from '../../../hooks/useCheckIsMobile';
import InstagramEmbeddedPost from '../../../components/InstagramEmbeddedPost';
import TwitterEmbeddedPost from '../../../components/TwitterEmbeddedPost';
import SubmitPost from './SubmitPost';

export default function UserPosts({ campaign, setIsLoading }) {
  const { id } = useParams();
  const isMobile = useCheckIsMobile();
  const {
    accountState: { account },
  } = useAppContext();
  const { posts, addPost } = useParticipantPost({
    campaignId: id,
    userId: account,
  });

  const instagramPosts = useMemo(
    () => posts.filter(({ url }) => url.includes('www.instagram.com/')),
    [posts]
  );

  const twitterPosts = useMemo(
    () => posts.filter(({ url }) => url.includes('twitter.com/')),
    [posts]
  );

  const renderPosts = (type) => {
    const filteredPosts = type === 'Instagram' ? instagramPosts : twitterPosts;
    const EmbeddedPost =
      type === 'Instagram' ? InstagramEmbeddedPost : TwitterEmbeddedPost;

    return (
      <Box>
        <p>
          <strong>{type}</strong>
        </p>
        {filteredPosts.length > 0 ? (
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={2}
            alignItems="flex-end"
          >
            {filteredPosts.map((post) => (
              <EmbeddedPost key={post.id} url={post.url} />
            ))}
            <SubmitPost
              socialMediaType={type}
              btnType="link"
              campaignName={campaign.name}
              setIsLoading={setIsLoading}
              addPost={addPost}
            />
          </Box>
        ) : (
          <Box
            sx={{ border: '1px solid #B1AEAE' }}
            textAlign="center"
            p={4}
            pt={['Ended', 'Closed'].includes(campaign.status) ? 4 : 1}
          >
            {['Ended', 'Closed'].includes(campaign.status) ? (
              <p style={{ fontSize: '1.25em' }}>
                <b>You didn&apos;t submit any posts.</b>
              </p>
            ) : (
              <>
                <p style={{ fontSize: '1.25em' }}>
                  <b>Start Posting to earn your rewards!</b>
                </p>
                <p>
                  <i>
                    To get started, start posting on {type} and upload your post
                    link to the dashboard here.
                    <br />
                    Your campaign details & progress can be track in the MIRL
                    campaign dashboard
                  </i>
                </p>
                <SubmitPost
                  socialMediaType={type}
                  campaignName={campaign.name}
                  setIsLoading={setIsLoading}
                  addPost={addPost}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box className="container">
      <h3>Your posts</h3>
      {renderPosts('Instagram')}
      {renderPosts('Twitter')}
    </Box>
  );
}
