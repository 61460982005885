import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Collapsible({
  containerClassName,
  children,
  expandButtonTitle,
  collapseButtonTitle = 'Show Less',
  isCollapsed,
  renderSameRowButton,
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    // collapse when user newly join campaign
    isCollapsed && setIsExpanded(false);
  }, [isCollapsed]);

  return (
    <Box className={`show-more__wrapper ${containerClassName}`}>
      <Box display="flex" justifyContent="center" gap={5}>
        <button
          className="secondary-btn with-icon"
          style={{ width: 240 }}
          onClick={() => setIsExpanded((current) => !current)}
        >
          {isExpanded ? collapseButtonTitle : expandButtonTitle}
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
        {renderSameRowButton && renderSameRowButton()}
      </Box>
      <Box className={`show-more__content ${isExpanded && 'show'}`}>
        {children}
      </Box>
    </Box>
  );
}
