import { useNavigate } from 'react-router-dom';

const BackButton = ({ url, text }) => {
  const navigate = useNavigate();

  if (window.location.pathname === '/') return null;

  return (
    <div
      className="container"
      style={{
        //         display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        // paddingLeft: 0,
        // paddingRight: 0,

        display: 'flex',
        gap: 8,
        fontSize: 16,
        margin: '0 auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          cursor: 'pointer',
        }}
        onClick={() => (url ? navigate(url) : navigate(-1))}
      >
        <img src="/images/ArrowBack.svg" alt="Back" width={10} height={10} />
        <span>{text || 'Go back'}</span>
      </div>
    </div>
  );
};

export default BackButton;
