export default function InstagramEmbeddedPost({ url }) {
  const urlWithoutParams = url.split('?')[0];
  const urlParts = urlWithoutParams.split('/');
  if (!urlParts[urlParts.length - 1].leng) urlParts.pop();
  const embedUrl = [...urlParts, 'embed'].join('/');

  return (
    <div style={{ background: 'white' }}>
      <iframe
        src={embedUrl}
        width="300"
        height="453"
        style={{ border: 'none' }}
      />
    </div>
  );
}
