import { Routes, Route, Navigate } from 'react-router-dom';

import HomeRoute from './HomeRoute';
import CampaignRoute from './CampaignRoute';
import VerifyRoute from './VerifyRoute';
import DeleteUserRoute from './DeleteUserRoute';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/campaigns/*" element={<CampaignRoute />} />
      <Route path="/verify/*" element={<VerifyRoute />} />
      <Route path="/auth/*" element={<DeleteUserRoute />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default MainRoutes;
