import { useState, useEffect, useCallback } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { getAddress } from '@ethersproject/address';
import moment from 'moment';

import environments from '../utils/environments';
import { toHexString } from '../utils/strings';

const { NETWORK_ID: networkId } = environments;

const NO_METAMASK_WARNING =
  'No Metamask extension found. Please install Metamask extension then reload this page';

const getWindow = () => (typeof window !== 'undefined' ? window : {});

const useEthereum = () => {
  const [account, setAccount] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const getEthereum = useCallback(() => {
    const { ethereum } = getWindow();

    if (!ethereum) {
      if (window.confirm(NO_METAMASK_WARNING)) {
        window.open('https://metamask.io/download/');
      }
    }

    return ethereum;
  }, []);

  const signMessage = useCallback(async () => {
    try {
      const ethereum = getEthereum();
      if (!ethereum) {
        return;
      }

      const provider = new Web3Provider(ethereum);
      const signer = provider.getSigner();

      const message = `Welcome to MIRL Wear2Earn!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nExpire time: ${moment()
        .add(1, 'day')
        .toDate()}`;

      const signature = await signer.signMessage(message);
      return { signature, message };
    } catch (err) {
      console.error(err);
    }
  }, [getEthereum]);

  const connectWallet = useCallback(async () => {
    const ethereum = getEthereum();
    if (!ethereum) return;

    setIsAuthenticating(true);

    try {
      await checkNetwork();
      let metamaskProvider = ethereum;
      // edge case if MetaMask and CoinBase are both installed
      if (ethereum.providers?.length) metamaskProvider = ethereum.providers.find((p) => p.isMetaMask);
      const accounts = await metamaskProvider.request({
        method: 'eth_requestAccounts',
      });

      if (!accounts.length) throw new Error('No authorized account found');
      setAccount(getAddress(accounts[0]));
      localStorage.setItem('connected', 'true');
    } catch (err) {
      console.error(err);
    }

    setIsAuthenticating(false);
  }, [getEthereum]);

  const checkNetwork = useCallback(async () => {
    const ethereum = getEthereum();
    if (!ethereum) return;

    if (ethereum.networkVersion !== networkId) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHexString(networkId) }],
      });
    }
  }, []);

  const disconnectWallet = useCallback(() => {
    setAccount(null);
    localStorage.removeItem('connected');
  }, []);

  const init = useCallback(async () => {
    const ethereum = getEthereum();
    if (!ethereum) {
      setIsInitialized(true);
      return;
    }

    if (localStorage.getItem('connected') !== 'true') {
      setIsInitialized(true);
      return;
    }

    const provider = new Web3Provider(ethereum);
    const signer = provider.getSigner();

    try {
      const address = await signer.getAddress();
      if (address) {
        await checkNetwork();
        setAccount(getAddress(address));
      }
    } catch (err) {
      console.error(err);
    }

    setIsInitialized(true);

    ethereum.on('accountsChanged', async (accounts) => {
      if (accounts[0]) {
        const newAccount = accounts[0];
        setAccount(getAddress(newAccount));
      } else {
        disconnectWallet();
      }
    });

    ethereum.on('chainChanged', (networkId) => {
      console.log({ networkId });
    });
  }, []);

  useEffect(() => {
    init();
  }, []);

  return {
    isInitialized,
    isAuthenticating,
    account,
    signMessage,
    connectWallet,
    disconnectWallet,
  };
};

export default useEthereum;
