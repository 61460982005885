import { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useCommunity = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let unsubscribe;

    unsubscribe = onSnapshot(collection(firestore, 'users'), (snapshot) => {
      const docs = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .filter((user) => !!user.avatar);

      setUsers(docs.slice(0, 60));
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  return { users };
};

export default useCommunity;
