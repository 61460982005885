import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  IconButton,
  DialogContent,
  Box,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useAppContext from '../../../hooks/useAppContext';

const validationSchema = yup.object({
  name: yup.string().trim().required('Your name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  campaignId: yup.string().required('Please select a campaign'),
  description: yup.string().trim().required('Description is required'),
});

export default function ReportCampaign({ campaignName, setIsLoading }) {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isReportRequestPending, setIsReportRequestPending] = useState(false);
  const {
    accountState: { account, user },
    profileState: { profile },
    campaignState: { campaigns },
    walletModalState: { openWalletModal },
    reportState: { createReport },
  } = useAppContext();

  useEffect(() => {
    if (isReportRequestPending && user) setIsOpen(true);
  }, [user, isReportRequestPending]);

  const initialValues = useMemo(
    () => ({
      name: '',
      email: profile?.email ?? '',
      campaignId: id,
      description: '',
    }),

    [profile, id]
  );

  const onClickReport = () => {
    if (!account || !user) {
      setIsReportRequestPending(true);
      openWalletModal();
      return;
    }
    setIsOpen(true);
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    await createReport({ ...values, campaignName });
    setIsLoading(false);
    closeDialog();
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const closeDialog = () => {
    handleReset();
    setIsOpen(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Button
          startIcon={<WarningAmberIcon />}
          onClick={onClickReport}
          sx={{ color: '#B2B2B2', textTransform: 'none' }}
        >
          Report
        </Button>
      </Box>

      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeDialog}>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ textAlign: 'center', px: 8 }} className="form">
          <h3 style={{ margin: 0, textAlign: 'center' }}>Report an Issue</h3>

          <p>
            Got an issue?
            <br /> Report it to us. We will try our best to get back to you as
            soon as possible.
            <br /> Due to high number of traffic, we may take 3-5 working days
            before getting back to you.
          </p>
          <Box className="form-field__container">
            <label>Wallet Address</label>
            <input value={account} disabled />
            <Box height="1rem" />
          </Box>
          <Box className="form-field__container">
            <label htmlFor="name">Name</label>
            <input
              name="name"
              value={values.name}
              placeholder="What should we call you?"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box height="1rem" px={3}>
              {touched.name && errors.name && (
                <Typography fontSize={16} color="tomato">
                  {errors.name}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className="form-field__container">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              value={values.email}
              placeholder="your.name@example.com"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box height="1rem" px={3}>
              {touched.email && errors.email && (
                <Typography fontSize={16} color="tomato">
                  {errors.email}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className="form-field__container">
            <label htmlFor="campaignId">Report Campaign</label>
            <select
              name="campaignId"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {campaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </select>
            <Box height="1rem" px={3}>
              {touched.campaignId && errors.campaignId && (
                <Typography fontSize={16} color="tomato">
                  {errors.campaignId}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className="form-field__container">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              value={values.description}
              rows="10"
              placeholder="Describe in details why are you reporting the campaign"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box height="1rem" px={3}>
              {touched.description && errors.description && (
                <Typography fontSize={16} color="tomato">
                  {errors.description}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-around" my={4}>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={closeDialog}
            >
              Back
            </button>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Box>
          <p style={{ fontSize: '0.75em' }}>
            <i>
              By submitting, you agreed to the consent of the use of information
              to verify, contact and identify you for any subsequent follow up.{' '}
            </i>
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
}
