import { Dialog, IconButton, DialogContent, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useAppContext from '../../../hooks/useAppContext';

export default function ConnectSocialsFirst({ isOpen, setIsOpen }) {
  const {
    profileModalState: { openProfileModal },
  } = useAppContext();

  const closeDialog = () => setIsOpen(false);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeDialog}>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ textAlign: 'center', px: 8 }} className="form">
        <h3 style={{ margin: 0, textAlign: 'center' }}>Whoops</h3>
        <p>
          It looks like you haven&apos;t link your social accounts yet. To join
          a campaign you will need to link your social accounts in order for to
          track your campaign progress.
        </p>
        <Box display="flex" justifyContent="space-around" mt={4}>
          <button
            className="secondary-btn square with-icon"
            onClick={openProfileModal}
          >
            Link Now <ChevronRightIcon />
          </button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
