import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

import { toHexString } from '../utils/strings';
import environments from '../utils/environments';
import MIRLCampaignContract from '../assets/abis/MIRLCampaign.json';

const {
  NETWORK_ID: networkId,
  CAMPAIGN_CONTRACT_ADDRESS,
} = environments;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useSmartContract = () => {
  const checkNetwork = async () => {
    const { ethereum } = window || {};
    if (!ethereum) return;

    if (ethereum.chainId !== toHexString(networkId)) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHexString(networkId) }],
      });
    }
  };

  const getSigner = () => {
    const { ethereum } = window || {};
    if (!ethereum) return null;

    const provider = new Web3Provider(ethereum);
    const signer = provider.getSigner();
    return signer;
  };

  const getCampaignContract = () => {
    const signer = getSigner();
    if (!signer) return null;

    const CampaignContract = new Contract(
      CAMPAIGN_CONTRACT_ADDRESS,
      MIRLCampaignContract.abi,
      signer
    );

    return CampaignContract;
  };

  const withdraw = async (data) => {
    await checkNetwork();
    await delay(1000);

    const CampaignContract = getCampaignContract();
    const tx = await CampaignContract?.withdraw(
    );
    const txnReceipt = await tx.wait();
    return txnReceipt;
  };

  return { withdraw };
};

export default useSmartContract;
