import { Routes, Route, Navigate } from 'react-router-dom';

import DeleteStatus from '../pages/DeleteUser/DeleteStatus';

const DeleteUserRoute = () => {
  return (
    <Routes>
      <Route path="/delete-status/:platform" element={<DeleteStatus />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default DeleteUserRoute;
