import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, IconButton, DialogContent, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useAppContext from '../../../hooks/useAppContext';
import ConnectSocialsFirst from './ConnectSocialsFirst';

export default function JoinCampaign({ name, setIsLoading, userHasJoined }) {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isConnectSocialsOpen, setIsConnectSocialsOpen] = useState(false);
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);
  const [pendingRequest, setRequestPending] = useState(null);
  const {
    accountState: { account, user },
    profileState: { profile },
    participantState: { addUserToCampaign, removeUserFromCampaign },
    walletModalState: { openWalletModal },
    profileModalState: { closeProfileModal },
  } = useAppContext();

  const userNeedsToLinkSocials =
    !profile?.socials?.twitter?.isVerified &&
    !profile?.socials?.instagram?.isVerified;

  useEffect(() => {
    if (pendingRequest === 'login' && user)
      userHasJoined ? closeDialog() : onClickJoin();
    if (isConnectSocialsOpen && !userNeedsToLinkSocials) {
      closeProfileModal();
      onClickJoin();
    }
  }, [
    user,
    isConnectSocialsOpen,
    pendingRequest,
    userNeedsToLinkSocials,
    userHasJoined,
  ]);

  const closeDialog = () => setIsOpen(false);
  const closeLeaveDialog = () => setIsLeaveDialogOpen(false);

  const checkPreconditions = () => {
    if (!account || !user) {
      setRequestPending('login');
      openWalletModal();
      return false;
    }

    if (userNeedsToLinkSocials) {
      setRequestPending('socials');
      setIsConnectSocialsOpen(true);
      return false;
    } else closeProfileModal();

    return true;
  };

  const onClickJoin = () => {
    if (!checkPreconditions()) return;
    setIsOpen(true);
  };

  const onConfirmJoin = () => {
    closeDialog();
    setIsConnectSocialsOpen(false);
    if (!checkPreconditions()) return;
    joinCampaign();
  };

  const onClickLeave = async () => {
    closeLeaveDialog();
    setIsLoading(true);
    await removeUserFromCampaign(id, name);
    setIsLoading(false);
  };

  const joinCampaign = async () => {
    setIsLoading(true);
    await addUserToCampaign(id, name);
    setIsLoading(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" pl={4}>
        {userHasJoined ? (
          <button
            className="hidden-btn"
            onClick={() => setIsLeaveDialogOpen(true)}
          >
            Joined
          </button>
        ) : (
          <button className="primary-btn" onClick={onClickJoin}>
            JOIN CAMPAIGN
          </button>
        )}
      </Box>

      <ConnectSocialsFirst
        isOpen={isConnectSocialsOpen}
        setIsOpen={setIsConnectSocialsOpen}
      />

      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeDialog}>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ textAlign: 'center' }}>
          <h3 style={{ margin: 0, textAlign: 'center' }}>
            Ready to join the campaign?
          </h3>

          <p style={{ marginTop: 0 }}>
            <em>
              Hurray! You are one step away from earning $MIRL tokens!
              <br />
              Make sure you understand the campaign requirements before joining!
            </em>
          </p>
          <Box display="flex" justifyContent="space-around" my={4}>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={closeDialog}
            >
              Go Back
            </button>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={onConfirmJoin}
            >
              Join Campaign
            </button>
          </Box>
          <p style={{ fontSize: '0.75em' }}>
            <i>
              By joining, you are agree to our terms and policies. You also
              agree to the campaign deliverables and you understand that the
              rewards or bounty are not guaranteed and it is subjected to
              results of the campaign approved by brands or the MIRL team.
            </i>
          </p>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isLeaveDialogOpen}
        onClose={closeLeaveDialog}
      >
        <IconButton
          aria-label="close"
          onClick={closeLeaveDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ textAlign: 'center' }}>
          <h3 style={{ margin: 0, textAlign: 'center' }}>
            Leave the campaign?
          </h3>

          <p style={{ marginTop: 0 }}>
            <em>
              Once you have left, all your progress will be lost.
              <br />
              If you join this campaign again, you will have to resubmit all
              your work, if any.
            </em>
          </p>
          <Box display="flex" justifyContent="space-around" mt={4}>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={closeLeaveDialog}
            >
              Go Back
            </button>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={onClickLeave}
            >
              Leave Anyway
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
