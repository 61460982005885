import { useState, useEffect, useCallback } from 'react';
import {
  query,
  doc,
  collection,
  onSnapshot,
  orderBy,
  where,
  addDoc,
  serverTimestamp,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';

import { firestore } from '../configs/firebase.config';

const useParticipants = ({ userId, campaignId }) => {
  const [participants, setParticipants] = useState([]);
  const [myCampaignIds, setMyCampaignIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (userId) {
      let unsubscribe;

      let q = query(
        collection(firestore, 'participants'),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const ids = snapshot.docs.map((doc) => doc.data().campaignId);

        setMyCampaignIds(ids);
      });

      return () => unsubscribe && unsubscribe();
    } else setMyCampaignIds([]);
  }, [userId]);

  useEffect(() => {
    if (campaignId) {
      let unsubscribe;

      let q = query(
        collection(firestore, 'participants'),
        where('campaignId', '==', campaignId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setParticipants(docs);
      });

      return () => unsubscribe && unsubscribe();
    } else setParticipants([]);
  }, [campaignId]);

  const addUserToCampaign = useCallback(
    async (campaignId, campaignName) => {
      try {
        if (!userId)
          throw new Error(
            'Please connect your wallet before joining a campaign'
          );

        const existingQuery = query(
          collection(firestore, 'participants'),
          where('campaignId', '==', campaignId),
          where('userId', '==', userId)
        );
        const existingCampaignUser = await getDocs(existingQuery);
        if (existingCampaignUser.size)
          throw new Error(
            'Already joined this campaign. Refresh page for latest information.'
          );

        await addDoc(collection(firestore, 'participants'), {
          campaignId,
          userId,
          createdAt: serverTimestamp(),
          status: 'pending',
        });

        enqueueSnackbar(
          `Joined the '${campaignName}' campaign successfully!!`,
          { variant: 'success' }
        );
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [userId, enqueueSnackbar]
  );

  const removeUserFromCampaign = useCallback(
    async (campaignId, campaignName) => {
      try {
        if (!userId)
          throw new Error(
            'Please connect your wallet before joining a campaign'
          );

        // delete participant
        const q = query(
          collection(firestore, 'participants'),
          where('campaignId', '==', campaignId),
          where('userId', '==', userId)
        );
        const participantDocs = await getDocs(q);
        participantDocs.docs.forEach(
          async (document) =>
            await deleteDoc(doc(firestore, 'participants', document.id))
        );

        // delete posts
        const userPostsQuery = query(
          collection(firestore, 'posts'),
          where('campaignId', '==', campaignId),
          where('userId', '==', userId)
        );
        const postDocs = await getDocs(userPostsQuery);
        const promises = [];
        postDocs.docs.forEach((document) =>
          promises.push(deleteDoc(doc(firestore, 'posts', document.id)))
        );
        await Promise.all(promises);

        enqueueSnackbar(`You have left campaign '${campaignName}'`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [userId, enqueueSnackbar]
  );

  return {
    myCampaignIds,
    participants,
    addUserToCampaign,
    removeUserFromCampaign,
  };
};

export default useParticipants;
