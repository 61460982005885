import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  IconButton,
  DialogContent,
  Box,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

const instagramPostLink =
  /^https:\/\/(?:www\.)?instagram\.com\/(p|reel)\/([^/?#&]+).*$/g;
const twitterPostLink =
  /^https:\/\/twitter.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)([^\/\?#&]+).*$/g;

export default function SubmitPost({
  socialMediaType,
  campaignName,
  setIsLoading,
  addPost,
  btnType,
}) {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [postPhoto, setPostPhoto] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(() => ({ url: '' }), []);

  const validationSchema = yup.object({
    url: yup
      .string()
      .trim()
      .required('Please add a link to your post')
      .matches(
        socialMediaType === 'Instagram' ? instagramPostLink : twitterPostLink,
        `Invalid ${socialMediaType} post link. Try clicking "Share" > "Copy link"`
      ),
  });

  const openDialog = () => setIsOpen(true);

  const onSubmit = async ({ url }) => {
    if (!postPhoto) {
      enqueueSnackbar('Please add a screenshot of your post!', {
        variant: 'warning',
      });
      return;
    }
    setIsLoading(true);
    await addPost({ url, postPhoto, campaignId: id }, campaignName);
    setIsLoading(false);
    closeDialog();
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const closeDialog = () => {
    handleReset();
    setIsOpen(false);
  };

  return (
    <>
      {btnType === 'link' ? (
        <a
          href="#"
          onClick={openDialog}
          style={{ textDecoration: 'underline', textDecorationThickness: 1 }}
        >
          Upload more &gt;
        </a>
      ) : (
        <Box display="flex" justifyContent="center">
          <button
            className="secondary-btn square with-icon"
            onClick={openDialog}
          >
            Upload Post <ChevronRightIcon />
          </button>
        </Box>
      )}

      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={closeDialog}>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ textAlign: 'center', px: 8 }} className="form">
          <h3 style={{ margin: 0, textAlign: 'center' }}>Upload your post</h3>
          <p>
            For us to start tracking your post, upload your campaign post in the
            panel here!
          </p>
          <Box className="form-field__container">
            <Box>
              <label htmlFor="postPhoto">Post Screenshot</label>
            </Box>
            <Box position="relative" width="fit-content" mx="auto">
              {postPhoto ? (
                <>
                  <IconButton
                    aria-label="close"
                    onClick={() => setPostPhoto(null)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.error.light,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <img
                    src={URL.createObjectURL(postPhoto)}
                    style={{ maxHeight: '500px' }}
                  />
                </>
              ) : (
                <input
                  id="postPhotoUpload"
                  name="postPhoto"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(event) =>
                    setPostPhoto(event.currentTarget.files[0])
                  }
                />
              )}
            </Box>
          </Box>
          <Box className="form-field__container">
            <label htmlFor="url">Social Post Link</label>
            <input
              name="url"
              value={values.url}
              placeholder="Copy the post's link and paste it here"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box height="1rem" px={3}>
              {touched.url && errors.url && (
                <Typography fontSize={16} color="tomato">
                  {errors.url}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" my={4}>
            <button
              className="secondary-btn"
              style={{ width: 150 }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Box>
          <p style={{ fontSize: '0.75em' }}>
            <i>
              Upon submitting, the post is non-editable, should there be amended
              to the original post.
              <br /> The management team have the rights to void your
              participation.
            </i>
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
}
