import { Box } from '@mui/material';

import useAppContext from '../../hooks/useAppContext';
import Layout from '../../components/Layout';
import { ConnectWalletContainer } from '../../components/ConnectWallet';
import CampaignsCarousel from './components/CampaignsCarousel';
import CommunityAvatars from './components/CommunityAvatars';

const Home = () => {
  const {
    accountState: { account },
    campaignState: { activeCampaigns, upcomingCampaigns, myCampaigns },
  } = useAppContext();

  return (
    <Layout>
      <Box
        className="container"
        py="0 !important"
        display="flex"
        justifyContent="space-between"
      >
        <h1 style={{ width: '20em' }}>Wear 2 Earn</h1>
        <ConnectWalletContainer />
      </Box>
      {account ? (
        <CampaignsCarousel
          title="My Campaigns"
          campaigns={[]}
          btnText="Joined"
          renderNoData={() => (
            <Box textAlign="center" border="1px solid #B1AEAE" px={10}>
              <p>
                Oh no! It seems that you haven&apos;t taken part in any of our
                campaigns yet.
              </p>
              <p>
                To become a part of our Wear2Earn movement, we encourage you to
                participate in any of our ongoing campaigns.
              </p>
              <p>
                You can check out our list of participating brands and current
                campaigns on our website{' '}
                <a href="#active" className="inline-link">
                  here
                </a>
                . We hope to see you soon!
              </p>
            </Box>
          )}
        />
      ) : (
        <Box className="container">
          <Box display="flex">
            <img src="/images/w2e-1.png" alt="" style={{ width: '50%' }} />
            <img src="/images/w2e-2.png" alt="" style={{ width: '50%' }} />
          </Box>
          <h2>Learn more about Wear2Earn by MIRL</h2>
          <p>
            MIRL&apos;s Wear 2 Earn program rewards users for supporting and
            purchasing from our brand and any participating brands in the
            program. We value our customers and see you as brand ambassadors who
            represent our vision and mission.
          </p>
          <p>
            By participating in Wear 2 Earn, you have the opportunity to wear
            MIRL&apos;s in-house or collaborative apparel and earn cash-backs in
            the physical (actual) and/or digital world (metaverse). Our aim is
            to create a conscious and strong community that supports the MIRL
            brand and other participating brands, promoting mutual support and
            communication, and spreading our message even further.
          </p>
          <p>
            Participating in Wear 2 Earn is easy and requires minimal knowledge
            and effort. Social media has become a part of our daily lives, and
            posting on platforms like Instagram, Tiktok, Twitter, or Facebook
            can help you earn rewards while showcasing your style. With fashion
            playing a significant role in our daily lives, the barrier to entry
            is almost negligible.
          </p>
          <p>
            Join us in the Wear 2 Earn program, and earn rewards for simply
            being stylish. Thank you for your support, and we look forward to
            seeing you as part of our community.
          </p>
        </Box>
      )}
      {activeCampaigns.length > 0 && (
        <CampaignsCarousel
          title="Active Campaigns"
          campaigns={activeCampaigns}
          backgroundColor="purple"
        />
      )}
      {upcomingCampaigns.length > 0 && (
        <CampaignsCarousel
          title="Upcoming Campaigns"
          campaigns={upcomingCampaigns}
        />
      )}
      <Box className="container" textAlign="center">
        <h3>
          Join 200+ members in <wbr /> our Wear2Earn program today!
        </h3>
        <CommunityAvatars />
      </Box>
    </Layout>
  );
};

export default Home;
