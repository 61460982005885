import { httpsCallable } from 'firebase/functions';

import { functions } from '../configs/firebase.config';

export const callables = {
  getFirebaseAuthToken: httpsCallable(functions, 'getFirebaseAuthToken'),
  createVerifyEmailRequest: httpsCallable(
    functions,
    'createVerifyEmailRequest'
  ),
  verifyEmail: httpsCallable(functions, 'verifyEmail'),
  getTwitterCodeVerifier: httpsCallable(functions, 'getTwitterCodeVerifier'),
  verifyTwitter: httpsCallable(functions, 'verifyTwitter'),
  verifyInstagram: httpsCallable(functions, 'verifyInstagram'),
  verifyTiktok: httpsCallable(functions, 'verifyTiktok'),
  updateEmail: httpsCallable(functions, 'updateEmail'),
  removeLinkings: httpsCallable(functions, 'removeLinkings'),
};
