import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Layout from '../../components/Layout';
import { ConnectWalletContainer } from '../../components/ConnectWallet';
import { callables } from '../../utils/functions';

const VerifyEmail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');

  const verify = async () => {
    try {
      await callables.verifyEmail({ id });
      setStatus('success');
    } catch (err) {
      console.log(err.message);
      console.error(err.reponse ? err.response.data : err.message);
      setStatus('error');
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    verify();
  }, [id]);

  const renderText = () => {
    if (status === 'loading') return 'Verifying your email...';
    if (status === 'success') return 'Your email is verified!';
    if (status === 'error')
      return errorMessage || 'Something is wrong. Please try again!';
    return '';
  };

  return (
    <Layout hideBackBtn>
      <Box
        className="container"
        py="0 !important"
        display="flex"
        justifyContent="space-between"
      >
        <h1
          style={{ width: '17em', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          Wear 2 Earn
        </h1>
        <ConnectWalletContainer />
      </Box>
      <Box
        height="400px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        {status === 'loading' && <CircularProgress color="secondary" />}
        {status === 'success' && <CheckCircleIcon color="success" />}
        <Typography>{renderText()}</Typography>
      </Box>
    </Layout>
  );
};

export default VerifyEmail;
