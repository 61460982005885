const environments = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  INSTAGRAM_APP_ID: process.env.REACT_APP_INSTAGRAM_APP_ID,
  TWITTER_CLIENT_ID: process.env.REACT_APP_TWITTER_CLIENT_ID,
  TIKTOK_CLIENT_KEY: process.env.REACT_APP_TIKTOK_CLIENT_KEY,
  CAMPAIGN_CONTRACT_ADDRESS: process.env.REACT_APP_CAMPAIGN_CONTRACT_ADDRESS,
};

export default environments;
