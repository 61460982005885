import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  collection,
  doc,
  onSnapshot,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import uniqBy from 'lodash/uniqBy';

import { firestore } from '../configs/firebase.config';

const instagramPostLink =
  /^https:\/\/(?:www\.)?instagram\.com\/(p|reel)\/([^/?#&]+).*$/g;
const twitterPostLink =
  /^https:\/\/twitter.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)([^/?#&]+).*$/g;

export default function useCampaignDetails() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      const unsubscribe = onSnapshot(
        doc(firestore, 'campaigns', id),
        (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.data();
            const status =
              data.startTime > Timestamp.now()
                ? 'Queued'
                : data.endTime > Timestamp.now()
                ? 'Ongoing'
                : !data.isDoneChoosingWinners
                ? 'Ended'
                : 'Closed';

            setCampaign({ ...data, status, id: snapshot.id });
          } else setCampaign(null);
          setIsLoaded(true);
        }
      );

      return () => unsubscribe && unsubscribe();
    } else {
      setCampaign(null);
      setIsLoaded(false);
    }
  }, [id]);

  useEffect(() => {
    if (id && campaign?.status === 'Ended') {
      const q = query(
        collection(firestore, 'posts'),
        where('campaignId', '==', id)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          platform: doc.data().url.match(instagramPostLink)
            ? 'Instagram'
            : 'Twitter',
        }));
        setPosts(docs);
      });

      return () => unsubscribe && unsubscribe();
    }
  }, [campaign?.status, id]);

  return { campaign, isLoaded };
}
