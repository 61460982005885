import { useState, useEffect, useMemo } from 'react';
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  where,
  Timestamp,
} from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useCampaigns = ({ myCampaignIds }) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    let unsubscribe;

    let q = query(
      collection(firestore, 'campaigns'),
      where('isPublic', '==', true),
      orderBy('startTime')
    );

    unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => {
        const data = doc.data();
        const status =
          data.startTime > Timestamp.now()
            ? 'Queued'
            : data.endTime > Timestamp.now()
            ? 'Ongoing'
            : !data.isDoneChoosingWinners
            ? 'Ended'
            : 'Closed';
        
        return {
          ...data,
          id: doc.id,
          status,
        };
      });

      setCampaigns(docs);
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  const activeCampaigns = useMemo(() => {
    return campaigns.filter(
      (campaign) =>
        campaign.startTime <= Timestamp.now() &&
        !(campaign.endTime < Timestamp.now())
    );
  }, [campaigns]);

  const upcomingCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => campaign.startTime > Timestamp.now());
  }, [campaigns]);

  const myCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => myCampaignIds.includes(campaign.id));
  }, [campaigns, myCampaignIds]);

  return { campaigns, activeCampaigns, upcomingCampaigns, myCampaigns };
};

export default useCampaigns;
