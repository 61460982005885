import { useEffect } from 'react';

export default function TwitterEmbeddedPost({ url }) {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('async', true);
    script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    head.appendChild(script);
  }, []);

  return (
    <blockquote className="twitter-tweet" width="300">
      <a href={url} />
    </blockquote>
  );
}
