import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import moment from 'moment';

const getDateString = (timestamp) =>
  moment(timestamp.toDate()).format('D MMM YYYY');

export default function CampaignCard({
  campaign,
  btnText,
  containerClassName,
}) {
  const navigate = useNavigate();
  const duration = `${getDateString(campaign.startTime)} - ${getDateString(
    campaign.endTime
  )}`;

  return (
    <div
      className={`campaign-card ${containerClassName} ${
        campaign.id && 'cursor-pointer'
      }`}
      onClick={() => navigate(`/campaigns/${campaign.id}`)}
    >
      <div className="campaign-card__img-container">
        <img src={campaign.image} alt={campaign.name} layout="fill" />
      </div>
      <div className="campaign-card__info-container">
        <div className="campaign-card__text-container">
          <div className="campaign-card__title">{campaign.name}</div>
          <div className="campaign-card__subtitle">{duration}</div>
          <div className="campaign-card__subtitle">
            Bounty: {campaign.holdersBounty + campaign.nonHoldersBounty} $MIRL
          </div>
        </div>
        <Box className="campaign-card__btn-container">
          {campaign.status === 'Ongoing' && (
            <>
              <div className="vote-button">{btnText ?? 'Join now'}</div>
              <div className="campaign-card__subtitle">
                {campaign.participantsCount} participants
              </div>
            </>
          )}
        </Box>
        {campaign.status === 'Queued' && (
          <a href="#featured-campaigns" className="vote-button">
            Coming soon
          </a>
        )}
      </div>
    </div>
  );
}
