import { Dialog, Box, Typography, Button } from '@mui/material';

import Loading from './Loading';

const ConfirmationModal = ({
  isLoading,
  open,
  onClose,
  title,
  text,
  confirmText,
  onConfirm,
}) => {
  if (!open) return null;

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <Loading isLoading={isLoading} />
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        {title && <Typography>{title}</Typography>}
        {text && <Typography>{text}</Typography>}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ fontSize: 13 }}
            onClick={onConfirm}
          >
            {confirmText || 'Confirm'}
          </Button>
          <Button
            variant="link"
            color="secondary"
            size="small"
            sx={{ fontSize: 13 }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
