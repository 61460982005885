import { useCallback, useState, useEffect } from 'react';
import {
  addDoc,
  updateDoc,
  query,
  collection,
  doc,
  onSnapshot,
  orderBy,
  where,
  serverTimestamp,
} from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';
import { TXN_TYPE } from '../utils/constants';

const useTransactions = ({ userId, userBalance }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (userId) {
      let unsubscribe;

      let q = query(
        collection(firestore, 'transactions'),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => {
          const data = doc.data();
          const description =
            data.type === TXN_TYPE.rewards
              ? `Rewards from campaign <b style="margin-left: 0.25em">${data.campaignName}</b>`
              : `Withdraw to wallet ${userId.slice(0, 4)}...${userId.slice(
                  -4
                )}`;

          return {
            ...data,
            id: doc.id,
            description,
          };
        });

        setTransactions(docs);
      });

      return () => unsubscribe && unsubscribe();
    } else setTransactions([]);
  }, [userId]);

  const createWithdrawTransaction = useCallback(
    async (transactionHash) => {
      await addDoc(collection(firestore, 'transactions'), {
        userId,
        amount: userBalance,
        type: TXN_TYPE.withdraw,
        createdAt: serverTimestamp(),
        metadata: { transactionHash },
      });

      await updateDoc(doc(firestore, 'users', userId), { balance: 0 });
    },
    [userBalance, userId]
  );

  return { transactions, createWithdrawTransaction };
};

export default useTransactions;
