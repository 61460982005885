import { useState, useMemo } from 'react';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import parse from 'react-html-parser';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import useAppContext from '../hooks/useAppContext';
import environments from '../utils/environments';

const { NETWORK_ID } = environments;

const etherscanPrefix = {
  1: '',
  5: 'goerli.',
  11155111: 'sepolia.',
};

const AccountHistory = ({ isLoading, setIsLoading }) => {
  const {
    transactionState: { transactions, createWithdrawTransaction },
    profileState: { profile },
    smartContractState: { withdraw },
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const renderedTransactions = useMemo(() => {
    const start = page * limit;
    const end = (page + 1) * limit;
    return transactions.slice(start, end);
  }, [transactions, page, limit]);

  const onWithdraw = async () => {
    setIsLoading(true);
    try {
      const { transactionHash } = await withdraw();
      await createWithdrawTransaction(transactionHash);
      enqueueSnackbar('Withdraw successful!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const columns = [
    { field: 'id', hidden: true, sortable: false },
    {
      field: 'created_at',
      headerName: 'Date/Time',
      width: 150,
      sortable: false,
      valueFormatter: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    { field: 'type', headerName: 'Type', sortable: false, width: 150 },
    {
      field: 'amount',
      headerName: 'Amount ($MIRL)',
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <Typography
          sx={{
            color:
              params.row.type === 'Withdraw' ? 'error.main' : 'success.main',
          }}
        >
          <strong>
            {params.row.type === 'Withdraw' ? '-' : '+'}{' '}
            {params.value.toLocaleString()}
          </strong>
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      renderCell: (params) => parse(params.value),
    },
    {
      field: 'metadata',
      headerName: '',
      sortable: false,
      renderCell: (params) =>
        params.value?.transactionHash ? (
          <Tooltip arrow title="View on Etherscan">
            <IconButton
              component="a"
              href={`https://${etherscanPrefix[NETWORK_ID]}etherscan.io/tx/${params.value.transactionHash}`}
              target="_blank"
            >
              <OpenInNewIcon color="info" sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <Box py={4} px={2} display="flex" flexDirection="column" gap={4}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          '& .MuiDataGrid-root, & .MuiDataGrid-root .MuiTablePagination-toolbar':
            { color: 'grey.900' },
          '& .MuiDataGrid-root .MuiTablePagination-toolbar svg': {
            color: 'grey.600',
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <MonetizationOnOutlinedIcon sx={{ fontSize: 14 }} />
          <Typography fontWeight={700} fontSize={22}>
            Account History
          </Typography>
        </Box>
        <Typography lineHeight="1rem">
          All your bounty transaction will be recorded here. You can withdraw
          your balance in this panel.
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography lineHeight="1rem">
            Available tokens:{' '}
            <strong>{profile.balance.toLocaleString()} $MIRL</strong>.
          </Typography>
          <Tooltip
            arrow
            title={
              profile.balance
                ? 'Withdraw all to wallet'
                : 'Join some campaigns to earn $MIRL'
            }
          >
            <span>
              <Button
                variant="contained"
                sx={{ textTransform: 'none', fontWeight: 700 }}
                disabled={!profile.balance || isLoading}
                onClick={onWithdraw}
              >
                Withdraw
              </Button>
            </span>
          </Tooltip>
        </Box>
        <Typography fontWeight={700} fontSize={22} mt={-2}>
          Transactions
        </Typography>
        <DataGrid
          sx={{ backgroundColor: '#E3E2E2', minHeight: '400px' }}
          columnVisibilityModel={{
            id: false,
          }}
          disableColumnFilter
          disableColumnMenu
          rows={renderedTransactions}
          columns={columns}
          rowCount={transactions.length}
          // loading={isLoading}
          rowsPerPageOptions={[25, 50]}
          pagination
          page={page}
          pageSize={limit}
          onPageChange={setPage}
          onPageSizeChange={setLimit}
        />
      </Box>
    </Box>
  );
};

export default AccountHistory;
