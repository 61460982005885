import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import useCheckIsMobile from '../../../hooks/useCheckIsMobile';
import CampaignCard from '../../../components/CampaignCard';

export default function CampaignsCarousel({
  campaigns,
  title,
  btnText,
  renderNoData,
  backgroundColor,
  fullWidth,
}) {
  const isMobile = useCheckIsMobile();

  const PROPOSALS_PER_SLIDE = isMobile ? 1 : 3;
  const numOfPages = Math.ceil(campaigns.length / PROPOSALS_PER_SLIDE);
  const pageArray = [...Array(numOfPages).keys()];
  const hasTwoPagesOrMore = numOfPages > 1;

  const renderCampaign = (campaign) => (
    <CampaignCard
      key={campaign.id}
      campaign={campaign}
      btnText={btnText}
      containerClassName="carousel-card"
    />
  );

  const renderCarousel = () => {
    return (
      <Carousel
        infiniteLoop
        // autoPlay
        showThumbs={false}
        renderArrowPrev={(onClick, hasPrev, label) =>
          renderArrowPrev(onClick, hasPrev, label, !hasTwoPagesOrMore)
        }
        renderArrowNext={(onClick, hasPrev, label) =>
          renderArrowNext(onClick, hasPrev, label, !hasTwoPagesOrMore)
        }
      >
        {pageArray.map((page) => (
          <div className="carousel-item" key={page}>
            {campaigns
              .slice(
                page * PROPOSALS_PER_SLIDE,
                (page + 1) * PROPOSALS_PER_SLIDE
              )
              .map(renderCampaign)}
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <div
      id={title.split(' ')[0].toLowerCase()}
      className={`campaigns-carousel ${backgroundColor}`}
    >
      <div className="container">
        <h2 className="title">{title}</h2>
        {campaigns.length > 0
          ? renderCarousel()
          : renderNoData && renderNoData()}
      </div>
    </div>
  );
}

const renderArrowPrev = (onClickHandler, hasPrev, label, isHidden) => (
  <button
    type="button"
    aria-label={label}
    className={`control-arrow control-prev ${hasPrev ? '' : 'disabled'} ${
      isHidden ? 'hidden' : ''
    }`}
    onClick={onClickHandler}
  >
    <img
      src="../images/IconCaretLeft.svg"
      width={14}
      height={28}
      alt="Arrow prev"
    />
  </button>
);

const renderArrowNext = (onClickHandler, hasNext, label, isHidden) => (
  <button
    type="button"
    aria-label={label}
    className={`control-arrow control-next ${hasNext ? '' : 'disabled'} ${
      isHidden ? 'hidden' : ''
    }`}
    onClick={onClickHandler}
  >
    <img
      src="../images/IconCaretRight.svg"
      width={14}
      height={28}
      alt="Arrow next"
    />
  </button>
);
