import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';
import { ConnectWalletModal } from './ConnectWallet';
import ProfileModal from './ProfileModal';

const Layout = ({ children, hideFooter, hideBackBtn, className }) => {
  return (
    <div>
      <div id="layout">
        <Header />
        <div className={`main-content ${className}`}>
          {!hideBackBtn && <BackButton />}
          <ConnectWalletModal />
          <ProfileModal />
          {children}
        </div>
        {!hideFooter && <Footer />}
      </div>
    </div>
  );
};

export default Layout;
