import { useState, useEffect, useCallback } from 'react';
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  serverTimestamp,
  addDoc,
  Timestamp,
  getDoc,
  doc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { firestore, storage } from '../configs/firebase.config';

const useParticipantPost = ({ campaignId, userId }) => {
  const [posts, setPosts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unsubscribe;

    if (campaignId && userId) {
      const q = query(
        collection(firestore, 'posts'),
        where('campaignId', '==', campaignId),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(docs);
      });
    } else {
      setPosts([]);
    }

    return () => unsubscribe && unsubscribe();
  }, [campaignId, userId]);

  const addPost = useCallback(
    async ({ url, postPhoto, campaignId }, campaignName) => {
      try {
        if (!userId)
          throw new Error(
            'Please connect your wallet before submitting a post'
          );

        const image = (await uploadFiles(userId, [postPhoto])).image;
        const now = new Date();
        now.setDate(now.getDate() - 1); // one day back
        now.setHours(0, 0, 0, 0); // time to 00:00
        const startOfYesterdayMillis = now.getTime();
        const startOfYesterday = Timestamp.fromMillis(startOfYesterdayMillis);

        const campaign = (await getDoc(doc(firestore,'campaigns',campaignId))).data()

        await addDoc(collection(firestore, 'posts'), {
          campaignId,
          campaignEndTime: campaign.endTime,
          userId,
          image,
          createdAt: serverTimestamp(),
          lastUpdateMetricsTime: startOfYesterday,
          url,
        });

        enqueueSnackbar(`Added a post to campaign '${campaignName}'!`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [userId, enqueueSnackbar]
  );

  const uploadFiles = useCallback(async (id, files, config = {}) => {
    const { path } = config;
    const promises = files.map(async (file) => {
      const type = getFileType(file);
      const folderPath = path ? `${path}/` : '';
      const storageRef = `users/${id}/${folderPath}${uuidv4()}-${file.name}`;
      const fileRef = ref(storage, storageRef);
      const metadata = { contentType: type };
      await uploadBytes(fileRef, file, metadata);
      const url = await getDownloadURL(fileRef);
      return {
        url,
        type: file.type,
        fileNameType: type,
        name: file.name,
        size: file.size,
        storageRef,
      };
    });

    const uploadedFiles = await Promise.all(promises);
    const imageFile = uploadedFiles.find((file) => file.type.includes('image'));

    return { uploadedFiles, image: imageFile?.url || null };
  }, []);

  return { posts, addPost };
};

const getFileType = (file) => {
  if (file.type) return file.type.split('/')[1];

  return file.name.split('.')[1];
};

export default useParticipantPost;
