import { createContext } from 'react';

import useAccount from '../hooks/useAccount';
import useSmartContract from '../hooks/useSmartContract';
import useProfile from '../hooks/useProfile';
import useCampaigns from '../hooks/useCampaigns';
import useParticipants from '../hooks/useParticipants';
import useReport from '../hooks/useReport';
import useWalletModal from '../hooks/useWalletModal';
import useProfileModal from '../hooks/useProfileModal';
import useTransactions from '../hooks/useTransactions';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // split to one state for each hook
  // then dont need to update this file when a hook change
  const walletModalState = useWalletModal();
  const profileModalState = useProfileModal();
  const accountState = useAccount(walletModalState);
  const smartContractState = useSmartContract(accountState.account);
  const profileState = useProfile(accountState.user);
  const participantState = useParticipants({ userId: accountState.user?.uid });
  const campaignState = useCampaigns({
    userId: accountState.user?.uid,
    myCampaignIds: participantState.myCampaignIds,
  });
  const transactionState = useTransactions({
    userId: accountState.user?.uid,
    userBalance: profileState.profile?.balance,
  });
  const reportState = useReport({ userId: accountState.user?.uid });

  return (
    <AppContext.Provider
      value={{
        accountState,
        smartContractState,
        campaignState,
        profileState,
        participantState,
        transactionState,
        reportState,
        walletModalState,
        profileModalState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
