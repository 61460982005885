import { useParams, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { Box } from '@mui/material';

import Layout from '../../components/Layout';
import { capitalize } from '../../utils/strings';

const Verify = () => {
  const { platform } = useParams();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  return (
    <Layout hideBackBtn>
      <Box p={2}>
        {['instagram', 'facebook'].includes(platform) &&
          code &&
          validator.isUUID(code) && (
            <h1>
              Your {capitalize(platform)} data deletion request (#{code}) has
              been completed
            </h1>
          )}
      </Box>
    </Layout>
  );
};

export default Verify;
