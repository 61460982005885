import { useCallback } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';

import { firestore } from '../configs/firebase.config';

export default function useReport({ userId }) {
  const { enqueueSnackbar } = useSnackbar();

  const createReport = useCallback(
    async ({ name, email, campaignId, description, campaignName }) => {
      try {
        if (!userId)
          throw new Error(
            'Please connect your wallet before reporting a campaign'
          );

        await addDoc(collection(firestore, 'reports'), {
          name,
          email,
          description,
          campaignId,
          userId,
          createdAt: serverTimestamp(),
          status: 'active',
        });
        enqueueSnackbar(
          `You have filed a report on campaign '${campaignName}'`,
          { variant: 'success' }
        );
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [userId, enqueueSnackbar]
  );

  return { createReport };
}
