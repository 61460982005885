import moment from 'moment';

export const dayDiff = (a, b) => {
  const dateA = a.toDate();
  const dateB = b.toDate();

  return Math.abs(moment(dateA).diff(moment(dateB), 'days'));
};

export const getTimezoneString = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezoneOffsetInHours = timezoneOffset / 60;
  const timezonePrefix = timezoneOffsetInHours > 0 ? '-' : '+';

  return `GMT${timezonePrefix}${Math.abs(timezoneOffsetInHours)}`
};
