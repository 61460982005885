import useCommunity from '../../../hooks/useCommunity';

export default function CommunityAvatars() {
  const { users } = useCommunity();

  return (
    <div className="community-container section">
      <div className="community">
        {users.map((user) => (
          <div key={user.id} className="community-item">
            <img
              src={getUserCommunityAvatar(user)}
              title={user.id}
              alt={user.id}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const getUserCommunityAvatar = (user) => {
  if (
    user.socials.twitter &&
    user.socials.twitter.isVerified &&
    user.socials.twitter.avatar
  ) {
    return user.socials.twitter.avatar;
  }

  return user.avatar;
};
